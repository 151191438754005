@import '../base/constants';

.calendar-header {
  &__row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  &__container {
    display: flex;
    margin: 1rem;
    justify-content: space-between;
  }

  &__title {
    font-size: $title-font-sz;
  }
}