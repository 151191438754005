@import '../base/fonts';

.calendar-event {
  &__container {
    padding: 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &__event {
    &-name {
      font-family: $font-roboto-bold;
    }
    &-start {
      font-family: $font-roboto-regular;
      font-weight: 100;
    }
  }
}