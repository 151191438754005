@import '../base/fonts';
@import '../base/constants';

.login-form {
  &__box {
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__logo {
    max-width: 400px;
    margin-bottom: 20px;
  }

  &__title {
    font-family: $font-roboto-regular;
    font-size: $title-font-sz;
    margin-bottom: 1rem;
  }

  &__form {

    &-container {
      width: 100%;
      padding: 40px 20px;
      background-color: #fff;
    }

    &-input {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  &__forgot-your-password {
    margin-top: 10px;
    font-family: $font-roboto-regular;
    
    &:hover {
      font-family: $font-roboto-medium;
      text-decoration: underline;
    }
  }

}

@media (min-width: $tablet-size-bk) {
  .login-form {
    &__box {
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #C9D6FF;
      background: -webkit-linear-gradient(to right, #E2E2E2, #C9D6FF);
      background: linear-gradient(to right, #E2E2E2, #C9D6FF);
    }

    &__form {
      &-container {
        max-width: 400px;
        border-radius: 12px;
      }
    }
  }
}