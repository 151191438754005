// https://fonts.google.com/specimen/Roboto?query=roboto

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('../../assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url('../../assets/fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('../../assets/fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Italic';
  src: url('../../assets/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url('../../assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url('../../assets/fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('../../assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../../assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../../assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('../../assets/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url('../../assets/fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

// Font variables
$font-roboto-bolditalic: 'Roboto-BoldItalic', sans-serif;
$font-roboto-medium: 'Roboto-Medium', sans-serif;
$font-roboto-thinitalic: 'Roboto-ThinItalic', sans-serif;
$font-roboto-black: 'Roboto-Black', sans-serif;
$font-roboto-italic: 'Roboto-Italic', sans-serif;
$font-roboto-mediumitalic: 'Roboto-MediumItalic', sans-serif;
$font-roboto-blackitalic: 'Roboto-BlackItalic', sans-serif;
$font-roboto-light: 'Roboto-Light', sans-serif;
$font-roboto-regular: 'Roboto-Regular', sans-serif;
$font-roboto-bold: 'Roboto-Bold', sans-serif;
$font-roboto-lightitalic: 'Roboto-LightItalic', sans-serif;
$font-roboto-thin: 'Roboto-Thin', sans-serif;
