.reset-password {
  &__container {
    height: 80vh;
  }

  &__card {
    width: 100%;
    border-radius: 0px;
  }
}

@media (min-width: $tablet-size-bk) {
  .reset-password {
    &__container {
      background-image: url('../../assets/images/bg/abstract_design_background_3007.jpg');
      background-size: cover;
      background-position: center;
      height: 100vh;
      &__card {
        width: 400px;
      }
    }
  }
}