@import '../base/constants';
.users{
    &__container{
        width: 100%;
        height: 200px;
        border: 2px solid red;
    }
    &__table{
      margin-top: 1%;
      margin-right: 5%;
    }
    &__button{
        &-add{
            border: 1px solid red;
            display: flex;
            align-items: flex-end;
            justify-content: end;
        }
    }
    &__total{
        &-rows{
            display: flex;
            align-items: flex-end;
            justify-content: end;
        }
    }
    &__buttons{
        &-principal{
            display: flex;
            gap: 4px;
            flex-direction: column;
            padding-left: 2px;
            padding-right: 2px;
            margin-left: 2%;
            margin-right: 2%;
            margin-top: 2%;
        }
    }
    &__save-button{
      display: flex;
      align-items: flex-end;
      justify-content: end;
      margin-top: 10%;
    }
}

@media (min-width: $tablet-size-bk) {
    .users{
        &__container{
            width: 100%;
            border: 2px solid blue;
        }
        &__title{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }  
}

@media (min-width: $desktop-size-bk) {
    .users{
        &__container{
            width: 100%;
            height: 100px;
            border: 2px solid green;
        }
    }
}
.abm-form {
    &__box {
      height: 80vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  
    &__title {
      font-family: $font-roboto-bold;
      font-size: $title-font-sz;
      margin-bottom: 1rem;
    }
  
    &__form {
  
      &-container {
        width: 100%;
        padding: 40px 20px;
        background-color: #fff;
      }
  
      &-input {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
  
  @media (min-width: $tablet-size-bk) {
    .abm-form {
      &__box {
        width: 100%;
        height: 93vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #C9D6FF;
        background: -webkit-linear-gradient(to right, #E2E2E2, #C9D6FF);
        background: linear-gradient(to right, #E2E2E2, #C9D6FF);
      }
  
      &__form {
        &-container {
          max-width: 400px;
          border-radius: 12px;
        }
      }
    }
  }